import { resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_c_search_list = _resolveComponent("c-search-list")
  const _component_c_table = _resolveComponent("c-table")
  const _component_el_dialog = _resolveComponent("el-dialog")
  const _directive_hasAuth = _resolveDirective("hasAuth")

  return (_openBlock(), _createBlock("div", null, [
    _createVNode(_component_el_dialog, {
      width: "1200px",
      title: "操作日志",
      modelValue: _ctx.isDialog,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.isDialog = $event)),
      "close-on-click-modal": false,
      onClose: _ctx.handleCloseDialog
    }, {
      default: _withCtx(() => [
        _withDirectives(_createVNode(_component_c_search_list, {
          inputList: _ctx.inputList,
          onOnSearch: _ctx.handleSearch,
          onOnReset: _ctx.handleReset
        }, null, 8, ["inputList", "onOnSearch", "onOnReset"]), [
          [_directive_hasAuth, 'user-manager-query']
        ]),
        _createVNode(_component_c_table, {
          dataSource: _ctx.tableConfig,
          onPageCurrentChange: _ctx.pageCurrentChange,
          onPageSizeChange: _ctx.pageSizeChange
        }, null, 8, ["dataSource", "onPageCurrentChange", "onPageSizeChange"])
      ]),
      _: 1
    }, 8, ["modelValue", "onClose"])
  ]))
}