
import CTable from '@/components/c-table'
import ModifyDialog from './modify-dialog.vue'
import LogDialog from './log-dialog.vue'
import { getTableConfig, getIndexConfig } from './config'
import CSearchList from '@/components/c-search-list'
import { defineComponent, reactive, toRefs } from 'vue'
import { ElMessageBox, ElMessage } from 'element-plus'
import { getList, deleteUser, getRoleList } from './api'
import moment from 'moment'
import { btnName } from '@/utils/index'

export default defineComponent({
  components: { CSearchList, CTable, ModifyDialog, LogDialog },

  setup() {
    // 表单组件变量
    const tableConfig = reactive({
      ...getTableConfig()
    })

    // 页面基础变量
    const data = reactive({
      ...getIndexConfig()
    })

    // 列表数据
    const getListFn = () => {
      const params = {
        currPage: tableConfig.pagination.currentPage,
        pageSize: tableConfig.pagination.pageSize
      }
      const obj = Object.assign(params, data.queryForm)
      tableConfig.loading = true
      getList(obj)
        .then(res => {
          tableConfig.data = res.data.list
          tableConfig.data.map(item => {
            item.updateTime = moment(item.updateTime).format(
              'YYYY-MM-DD HH:mm:ss'
            )
            item.userStatusTxt = item.userStatus ? '正常' : '禁用'
          })

          tableConfig.pagination.total = res.data.total
        })
        .finally(() => {
          tableConfig.loading = false
        })
    }

    // 执行搜索
    const handleSearch = (params: object) => {
      data.queryForm = params
      getListFn()
    }

    // 执行重置
    const handleReset = (params: object) => {
      data.queryForm = params
    }

    // 表单设置页数
    const pageSizeChange = (val: number) => {
      tableConfig.pagination.currentPage = 1
      tableConfig.pagination.pageSize = val
      getListFn()
    }

    // 表单翻页
    const pageCurrentChange = (val: number) => {
      tableConfig.pagination.currentPage = val
      getListFn()
    }

    // 表单操作栏按钮
    const btnHandle = (type: string, row: any) => {
      switch (type) {
        case 'add': {
          data.title = '新建用户'
          data.rowData = {}
          data.visible = true
          break
        }
        case 'edit': {
          data.title = '修改用户'
          data.rowData = Object.assign({}, row)
          data.visible = true
          break
        }
        case 'log': {
          data.id = row.accountId
          data.logVisible = true
          break
        }
        case 'del': {
          ElMessageBox.confirm(
            `确定要删除用户 ${row.nickName} 吗？`,
            '删除用户',
            {
              confirmButtonText: '确认删除',
              cancelButtonText: '取消',
              type: 'warning'
            }
          ).then(() => {
            deleteUser({ id: row.id }).then((res: any) => {
              ElMessage.success(res.msg)
              getListFn()
            })
          })
          break
        }
      }
    }

    // 搜索已授权角色
    data.inputList.forEach(item => {
      if (item.key === 'roleId') {
        const arr = []
        getRoleList({ pageSize: 9999 }).then((res: any) => {
          res.data.list.forEach(item => {
            arr.push({
              label: item.roleName,
              value: item.roleId
            })
          })
          item.options = arr
        })
      }
    })

    // 关闭弹框
    const closeDialog = val => {
      data.visible = val
      data.logVisible = val
    }

    return {
      tableConfig,
      ...toRefs(data),
      handleSearch,
      handleReset,
      getListFn,
      closeDialog,
      btnHandle,
      pageCurrentChange,
      pageSizeChange,
      btnName
    }
  },
  mounted() {
    this.getListFn()
  }
})
