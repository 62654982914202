// 表单组件基础信息
export const getTableConfig = () => {
  // table 通用属性
  const common = {
    // table 有多选时设置
    isSelection: false,
    // table loading
    loading: false,
    // table 的高度
    height: null,
    // table 是否为斑马纹
    stripe: true,
    // table 的尺寸 medium / small / mini
    size: 'small',
    // table 是否开启索引
    isIndex: true,
    // table 索引 label
    indexLabel: '序号',
    // table 数据
    data: []
  }

  // table 分页
  const pagination = {
    // 是否展示分页
    isShow: true,
    // 总条数
    total: 0,
    // 每页显示条目个数
    pageSize: 20,
    // 当前页数
    currentPage: 1,
    // 每页显示个数选择器的选项设置
    pageSizes: [10, 20, 40, 80, 100]
  }

  // table 列数据
  const column = [
    {
      key: 'nickName',
      label: '姓名',
      width: '180'
    },
    {
      key: 'mobile',
      label: '手机号',
      width: '180'
    },
    {
      key: 'roleStatusDes',
      label: '已授权角色',
      width: '180'
    },
    {
      key: 'userStatusTxt',
      label: '状态',
      width: '180'
    },
    {
      key: 'operator',
      label: '操作人',
      width: '180'
    },
    {
      key: 'updateTime',
      label: '更新时间',
      width: '180'
    },
    {
      key: 'action',
      label: '操作',
      width: '180',
      fixed: 'right',
      slot: 'action'
    }
  ]

  return {
    ...common,
    pagination,
    column
  }
}

// 页面搜索和基础变量
export const getIndexConfig = () => {
  // 搜索变量
  const inputList = [
    {
      type: 'input',
      label: '手机号',
      key: 'mobile',
      placeholder: '请输入',
      labelWidth: 100,
      inputWidth: 200,
      maxlength: 11,
      rules: { pattern: /^[1][3,4,5,7,8,9][0-9]{9}$/, message: '请输入正确手机号', trigger: 'blur' }
    }, {
      type: 'input',
      label: '姓名',
      key: 'userNameFuzzy',
      placeholder: '请输入',
      labelWidth: 100,
      inputWidth: 200,
      maxlength: 10,
      rules: {}
    },
    {
      type: 'select',
      label: '已授权角色',
      key: 'roleId',
      placeholder: '请选择',
      labelWidth: 100,
      inputWidth: 200,
      options: [],
      isMultiple: false,
      isFilterable: true,
      isCollapseTags: false
    },
    {
      type: 'select',
      label: '状态',
      key: 'userStatus',
      placeholder: '请选择',
      labelWidth: 100,
      inputWidth: 200,
      options: [
        {
          label: '正常',
          value: 1
        }, {
          label: '禁用',
          value: 0
        }
      ],
      rules: {
        type: 'number'
      }
    }
  ]

  // 页面基础变量
  const page = {
    queryForm: {}, // 搜索参数
    total: 0,
    visible: false, // 弹框显示
    logVisible: false,
    title: '新建用户',
    id: null,
    rowData: null // 单行数据
  }

  return {
    inputList,
    ...page
  }
}
