/*
 * @Description:
 * @Author: rongcheng
 * @@后台人员: xxx
 * @Date: 2021-04-09 15:04:51
 * @LastEditors: rongcheng
 * @LastEditTime: 2022-04-18 11:52:08
 */
import service from '@/utils/request'

// 列表数据查询
export const getList = (pramas: any) =>
  service.request({
    method: 'post',
    url: '/admin/employee/getPageOfUser',
    data: pramas
  })

// 添加员工
export const addUser = (pramas: any) =>
  service.request({
    method: 'post',
    url: '/admin/employee/addUser',
    data: pramas
  })

// 删除员工
export const deleteUser = (pramas: any) =>
  service.request({
    method: 'post',
    url: '/admin/employee/deleteUser',
    data: pramas
  })

// 修改员工
export const editUser = (pramas: any) =>
  service.request({
    method: 'post',
    url: '/admin/employee/editUser',
    data: pramas
  })

// 角色数据
export const getRoleList = (pramas: any) =>
  service.request({
    method: 'post',
    url: '/admin/role/getRoleList',
    data: pramas
  })

// 操作日志
export const getPageOfLog = (pramas: any) =>
  service.request({
    method: 'post',
    url: '/admin/employee/getPageOfLog',
    data: pramas
  })

// 操作模块列表
export const getListOfOperationMenu = (pramas: any) =>
  service.request({
    method: 'post',
    url: '/admin/log/getListOfOperationMenu',
    data: pramas
  })

// 操作类型列表
export const getListOfOperationType = (pramas: any) =>
  service.request({
    method: 'post',
    url: '/admin/log/getListOfOperationType',
    data: pramas
  })
