export const getTableConfig = () => {
  // table 通用属性
  const common = {
    // table 有多选时设置
    isSelection: false,
    // table loading
    loading: false,
    // table 的高度
    height: null,
    // table 是否为斑马纹
    stripe: true,
    // table 的尺寸 medium / small / mini
    size: 'small',
    // table 是否开启索引
    isIndex: true,
    // table 索引 label
    indexLabel: '序号',
    // table 数据
    data: []
  }

  // table 分页
  const pagination = {
    // 是否展示分页
    isShow: true,
    // 总条数
    total: 100,
    // 每页显示条目个数
    pageSize: 20,
    // 当前页数
    currentPage: 1,
    // 每页显示个数选择器的选项设置
    pageSizes: [10, 20, 40, 80, 100]
  }

  // table 列数据
  const column = [
    {
      key: 'operationContent',
      label: '操作内容',
      width: '120'
    },
    {
      key: 'operationMenu',
      label: '操作模块',
      width: '120'
    },
    {
      key: 'operationType',
      label: '操作类型',
      width: '120'
    },
    {
      key: 'dataStatus',
      label: '数据状态',
      width: '160'
    },
    {
      key: 'updateTime',
      label: '操作时间',
      width: '130'
    }
  ]

  return {
    ...common,
    pagination,
    column
  }
}

// 页面搜索和基础变量
export const getIndexConfig = () => {
  // 搜索变量
  const inputList = [
    {
      type: 'select',
      label: '操作模块',
      key: 'operationMenu',
      placeholder: '请选择',
      labelWidth: 100,
      inputWidth: 200,
      options: []
    }, {
      type: 'select',
      label: '操作类型',
      key: 'operationType',
      placeholder: '请选择',
      labelWidth: 100,
      inputWidth: 200,
      options: []
    }, {
      type: 'datePicker',
      label: '日期时间范围',
      key: 'dateTimeArea',
      placeholder: '请选择',
      labelWidth: 100,
      inputWidth: 250,
      format: 'YYYY-MM-DD HH:mm:ss',
      // 设置禁用状态，参数为当前日期，要求返回 Boolean
      disabledDate: () => {},
      // 日期类型 year/month/date/dates/ week/datetime/datetimerange/ daterange/monthrange
      dateType: 'datetimerange',
      // 开启日期范围后生效
      startPlaceholder: '开始日期',
      endPlaceholder: '结束日期',
      rules: {
        // 默认 date 开启范围 array
        type: 'array'
      }
    }
  ]

  // 页面基础变量
  const page = {
    queryForm: null, // 搜索参数
    currPage: 1,
    pageSize: 20,
    total: 0
  }

  return {
    inputList,
    ...page
  }
}
